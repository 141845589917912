const { CURRENT_RPC, showNotification } = require("./globals.js");

const { nftContractAddress, nftContractAbi } = require("./ghostMeta.js");
const { diggerContractAddress, diggerContractAbi } = require("./diggerMeta.js");
const { graveyardContractAddress, graveyardContractAbi } = require("./graveyardMeta.js");
const { spiritContractAddress, spiritContractAbi } = require("./spiritMeta.js");

const { ethers } = require("ethers");

const { signer, hasSelectedAddress } = require("./wallet.js");

const { log } = require("./logger.js");

const provider = new ethers.providers.JsonRpcProvider(CURRENT_RPC.rpcUrls[0]);

let contract;
let digger;
let graveyard;
let spirit;

let isContractInitialized;

let isOwner;

let currentItemCount;
let maxItemCount;

let balance;

let itemFee;
let team;
let mintPaused;
let graveyardLevel;
let harvestAmount;
let spiritBalance;
let graveyardLevelUpCost;
let graveyardMaxLevel;
let harvestingLevelMultiplier;
let harvestingBaseAmount;
let harvestingBonusAmount;
let harvestingTeamMultiplier;
let changeSideCostEther;
let changeSideCostSpirit;
let changeSideEnabled;

const defaultDecimals = 18; // 18 for Fungibles

async function initialize() {

    constructContracts();

    if(contract && digger && graveyard && spirit) {
        log("[CONTRACT]: Contract is initialized");
        isContractInitialized = true;
        document.addEventListener('onProviderReset', () => {
            log("[CONTRACT]: onProviderReset event raised!");
            constructContracts();
        });
        hookMintEvents();
        hookDiggerEvents();
        dispatchContractInitializedEvent();
    } else {
        log("[CONTRACT]: Contract could not be initialized");
    }

}

function constructContracts() {
    if(signer()) {
        contract = new ethers.Contract(nftContractAddress, nftContractAbi, signer() ?? provider);
        digger = new ethers.Contract(diggerContractAddress, diggerContractAbi, signer());
        graveyard = new ethers.Contract(graveyardContractAddress, graveyardContractAbi, signer());
        spirit = new ethers.Contract(spiritContractAddress, spiritContractAbi, signer());
    } else {
        contract = new ethers.Contract(nftContractAddress, nftContractAbi, provider);
        digger = new ethers.Contract(diggerContractAddress, diggerContractAbi, provider);
        graveyard = new ethers.Contract(graveyardContractAddress, graveyardContractAbi, provider);
        spirit = new ethers.Contract(spiritContractAddress, spiritContractAbi, provider);
    }
}

async function getCurrentItemCount() {
    return await contract.totalSupply();
}

async function getMaxItemCount() {
    return await contract.maxSupply();
}

async function queryMintStats() {

    if(!contract) {
        return;
    }

    if(!signer()) {
        return;
    }

    [itemFee, mintPaused, maxItemCount, currentItemCount] = await Promise.all([getItemPrice(), isPaused(), getMaxItemCount(), getCurrentItemCount()]);
    
    log("[CONTRACT]: Current item fee is " + itemFee);

    log("[CONTRACT]: Mint is paused ? " + mintPaused);

    log("[CONTRACT]: Maximum supply is ? " + maxItemCount);

    log("[CONTRACT]: Minted NFT count is ? " + currentItemCount);

    dispatchMintStatsFetched();

}

async function queryGameStats() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();

    [
        balance, 
        team, 
        graveyardLevel, 
        harvestAmount, 
        spiritBalance, 
        graveyardLevelUpCost, 
        graveyardMaxLevel,
        harvestingBaseAmount,
        harvestingBonusAmount
    ] = await Promise.all([
        getGhostBalance(address), 
        getTeam(address), 
        getGraveyardLevel(address), 
        getHarvestAmount(address), 
        getSpiritBalance(address), 
        getGraveyardLevelUpCost(address),
        getGraveyardLevelCap(),
        getBaseStaking(),
        getStakingBonus(address)
    ]);

    harvestingLevelMultiplier = graveyardLevel; // (BASE + BONUS) * LEVEL

    harvestingTeamMultiplier = team === 1 
    ? await getPurpleTeamStakingMultiplier() 
    : team === 2 
    ? await getGreenTeamStakingMultiplier() 
    : 1;

    [changeSideCostEther, changeSideCostSpirit, changeSideEnabled] = await Promise.all([getChangeSideCostEther(), getChangeSideCostSpirit(), isChangingSideEnabled()]);
    
    log("[CONTRACT]: Current team is " + team);

    log("[CONTRACT]: Current graveyard level is " + graveyardLevel);

    log("[CONTRACT]: Current harvest amount is " + harvestAmount);

    log("[CONTRACT]: Current spirit balance is " + spiritBalance);
    
    log("[CONTRACT]: Current graveyard level up cost is " + graveyardLevelUpCost);
    
    log("[CONTRACT]: Graveyard level cap is " + graveyardMaxLevel);

    log("[CONTRACT]: Base harvesting amount is " + harvestingBaseAmount);

    log("[CONTRACT]: Bonus harvesting amount is " + harvestingBonusAmount);

    log("[CONTRACT]: Player team harvesting multiplier is " + harvestingTeamMultiplier);
    
    log("[CONTRACT]: Change side cost (AVAX) is " + changeSideCostEther);
    
    log("[CONTRACT]: Change side cost ($SPRT) is " + changeSideCostSpirit);

    dispatchGameStatsFetched();

}

async function getGhostBalance(address) {
    return await contract.balanceOf(address);
}

async function getHarvestAmount(address) {
    const amount = await digger.harvestAmount(address);
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getSpiritBalance(address) {
    const amount = await spirit.balanceOf(address);
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getGraveyardLevel(address) {
    return await digger.graveyardLevelOf(address);
}

async function getTeam(address) {
    return await digger.teamOf(address);
}

async function isPaused() {
    return await contract.paused();
}

async function getGraveyardLevelUpCost(address) {
    const amount = await digger.graveyardLevelUpCost(address);
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getItemPrice() {
    const amount = await contract.itemFee();
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getGraveyardLevelCap() {
    return await digger.graveyardLevelCap();
}

async function getChangeSideCostEther() {
    const amount = await digger.sideChangeCostEther();
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getChangeSideCostSpirit() {
    const amount = await digger.sideChangeCostSpirit();
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getBaseStaking() {
    const amount = await digger.baseStakingAmount();
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getStakingBonus(address) {
    const amount = await contract.stakingBonus(address);
    return ethers.utils.formatUnits(amount, defaultDecimals);
}

async function getPurpleTeamStakingMultiplier() {
    return await digger.purpleTeamStakingMultipler();
}

async function getGreenTeamStakingMultiplier() {
    return await digger.greenTeamStakingMultipler();
}

async function isChangingSideEnabled() {
    return await digger.changeSideEnabled();
}

async function queryHarvestAmount() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();

    harvestAmount = await getHarvestAmount(address);

    log("[CONTRACT]: Queried harvest amount is " + harvestAmount);
    
}

async function querySpiritBalance() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();

    spiritBalance = await getSpiritBalance(address);

}

async function burn() {

    if(!isOwner) {
        return;
    }

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();
    log("ADDRESS => " + address);

    const itemId = (currentItemCount ?? 1) - 1;

    contract.destroyItem(itemId).then(() => {
        log("[BURN]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[BURN]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function mint(amount) {

    if(!signer()) {
        return;
    }

    if(amount < 1 && amount > 20) {
        return;
    }

    const address = await signer().getAddress();
    log("ADDRESS => " + address);

    const itemPrice = this.itemFee ?? await getItemPrice();

    const overrides = {
        value: ethers.utils.parseEther((itemPrice * amount).toString())
    };

    contract.mint(amount, overrides).then(() => {
        log("[MINT]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[MINT]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function constructGraveyard(team) {

    if(!signer()) {
        return;
    }

    // 1 Purple, 2 Green
    if(team < 1 || team > 2) {
        return;
    }

    digger.constructGraveyard(team).then(() => {
        log("[CONSTRUCT_GRAVEYARD]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[CONSTRUCT_GRAVEYARD]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function harvest() {

    if(!signer()) {
        return;
    }

    digger.harvest().then(() => {
        log("[HARVEST]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[HARVEST]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function levelUp() {

    if(!signer()) {
        return;
    }

    digger.levelUp().then(() => {
        log("[LEVEL_UP]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[LEVEL_UP]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function changeSide(side) {

    if(!changeSideEnabled) {
        return;
    }

    if(!signer()) {
        return;
    }

    if(side === team) {
        return;
    }

    const etherWay = parseFloat(spiritBalance) < parseFloat(changeSideCostSpirit);

    const overrides = {
        value: etherWay ? ethers.utils.parseEther(changeSideCostEther) : 0
    };

    log(overrides);

    digger.changeTeam(side, overrides).then(() => {
        log("[LEVEL_UP]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[LEVEL_UP]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function queryLeaderboard(size = 200) {

    if(size < 10 || size > 200) {
        return;
    }

    if(!signer()) {
        return;
    }

    const scoreboard = await digger.getScoreboard();

    if(scoreboard && scoreboard.length > 0) {
        return scoreboard.slice().sort(function(a, b) {
            if (a[1] === b[1]) {
                return ethers.utils.formatUnits(b[2], defaultDecimals) - ethers.utils.formatUnits(a[2], defaultDecimals);
            }
            return b[1] - a[1];
        }).slice(0, size);
    }
    
    return;

}

async function queryTeamScore() {

    if(!signer() && hasSelectedAddress()) {
        return;
    }

    const scoreboard = await digger.getScoreboard();

    if(scoreboard && scoreboard.length > 0) {

        let purpleScore = 0, greenScore = 0;

        scoreboard.forEach((element) => {
            const score = parseFloat(ethers.utils.formatUnits(element.score, defaultDecimals));
            switch(element.team) {
                case 1:
                    purpleScore += score;
                    break;
                case 2:
                    greenScore += score;
                    break;
            }
        });

        return { purple: purpleScore, green: greenScore };

    }

    return;

}

async function queryGhosts() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();

    const ghostIds = await contract.tokensOfOwner(address);

    if(!ghostIds) {
        return;
    }
    
    var ghosts = [];

    for(var i = 0; i < ghostIds.length; i++) {
        await fetch("https://nftstorage.link/ipfs/bafybeid3mfjdgj6xrfziyd6wimdlavkfwfgpoikyhls4bff7bzftps4ajy/" + ghostIds[i] + ".json").then(res => res.json()).then((out) => {
            ghosts.push(out);
        });
    }

    return ghosts;

}

async function withdrawMint() {

    if(!isOwner) {
        return;
    }

    if(!signer()) {
        return;
    }

    contract.withdraw().then(() => {
        log("[WITHDRAW]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[WITHDRAW]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function withdrawGame() {

    if(!isOwner) {
        return;
    }

    if(!signer()) {
        return;
    }

    digger.withdraw().then(() => {
        log("[WITHDRAW]: Transaction submitted");
        showNotification("Processing", "Transaction submitted."); 
    }, (error) => {
        log("[WITHDRAW]: Transaction rejected");
        showErrorNotification(error);
    });

}

async function hookMintEvents() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();

    const filterTo = contract.filters.Transfer(null, address);

    contract.on(filterTo, (from, to, amount) => {
        // The `to` will always be the signer address
        log("[MINT]: Transaction completed, " + from + " => " + to + ", amount " + ethers.utils.formatUnits(amount, defaultDecimals));
        showNotification("👻 BOO!", "You've just mint an AVAXGHOST!", "success");
        setTimeout(() => {
            refreshMintStats();
        }, 1024);
    });

}

async function hookDiggerEvents() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();

    const diggerReady = digger.filters.DiggerReady(address, null);
    const harvest = digger.filters.Harvest(address, null);
    const levelUp = digger.filters.LevelUp(address, null);
    const teamChanged = digger.filters.TeamChanged(address, null);

    digger.on(diggerReady, (from, team) => {
        log("[DIGGER_READY]: Transaction completed, " + from + ", team is " + team);
        showNotification("Success", "Your graveyard is ready!", "success");
        setTimeout(() => {
            refreshGameStats();
            dispatchConstructGraveyardSucceedEvent();
        }, 1024);
    });

    digger.on(harvest, (from, amount) => {
        log("[HARVEST]: Transaction completed, " + from + ", harvested amount is " + ethers.utils.formatUnits(amount, 18));
        showNotification("Success", "Harvest success!", "success");
        setTimeout(() => {
            refreshGameStats();
            dispatchHarvestSucceedEvent();
        }, 1024);
    });

    digger.on(levelUp, (from, value) => {
        log("[LEVEL_UP]: Transaction completed, " + from + ", new level is " + value);
        showNotification("Success", "Level up success!", "success");
        setTimeout(() => {
            refreshGameStats();
            dispatchLevelUpSucceedEvent();
        }, 1024);
    });

    digger.on(teamChanged, (from, team) => {
        log("[TEAM_CHANGED]: Transaction completed, " + from + ", new team is " + team);
        showNotification("Success", "Team change success!", "success");
        setTimeout(() => {
            refreshGameStats();
            dispatchChangeSideSucceedEvent();
        }, 1024);
    });

}

async function checkOwnership() {

    if(!signer()) {
        return;
    }

    const address = await signer().getAddress();
    log("ADDRESS => " + address);

    const owner = await contract.owner();
    log("Contract owner is " + owner);

    isOwner = address === owner;

}

async function refreshMintStats() {
    await queryMintStats();
}

async function refreshGameStats() {
    await queryGameStats();
}

function dispatchContractInitializedEvent() {
    const event = new Event('onContractInitialized');
    document.dispatchEvent(event);
}

function dispatchMintStatsFetched() {
    const event = new Event('onMintStatsFetched');
    document.dispatchEvent(event);
}

function dispatchGameStatsFetched() {
    const event = new Event('onGameStatsFetched');
    document.dispatchEvent(event);
}

function dispatchConstructGraveyardSucceedEvent() {
    const event = new Event('onGraveyardConstructed');
    document.dispatchEvent(event);
}

function dispatchHarvestSucceedEvent() {
    const event = new Event('onHarvestSucceed');
    document.dispatchEvent(event);
}

function dispatchLevelUpSucceedEvent() {
    const event = new Event('onLevelUpSucceed');
    document.dispatchEvent(event);
}

function dispatchChangeSideSucceedEvent() {
    const event = new Event('onChangeSideSucceed');
    document.dispatchEvent(event);
}

function showErrorNotification(error) {
    var message = "Your transaction is rejected.";
    if(error) {
        if(error.data) {
            if(error.data.message) {
                message = error.data.message;
            } else if(error.message) {
                message = error.message;
            }
        } else if(error.message) {
            message = error.message;
        }
    }
    if(message.includes('insufficient funds')) {
        message = "Insufficient AVAX Balance";
    } else if(message.includes('Invalid mint amount')) {
        message = "You can mint up to 20 NFTs in one transaction. For more, you will need to do more than one transaction.";
    }
    showNotification("Failure", message, "danger");
}

export { 
    nftContractAddress,
    diggerContractAddress,
    graveyardContractAddress,
    spiritContractAddress, 
    initialize,
    isContractInitialized,
    isOwner,
    mintPaused,
    currentItemCount, 
    maxItemCount,
    itemFee,
    balance,
    team,
    graveyardLevel,
    harvestAmount,
    spiritBalance,
    graveyardLevelUpCost,
    graveyardMaxLevel,
    harvestingBaseAmount,
    harvestingBonusAmount,
    harvestingLevelMultiplier,
    harvestingTeamMultiplier,
    changeSideCostEther,
    changeSideCostSpirit,
    changeSideEnabled,
    queryMintStats,
    queryGameStats,
    queryHarvestAmount,
    querySpiritBalance,
    queryTeamScore,
    getItemPrice,
    burn,
    mint,
    constructGraveyard,
    harvest,
    levelUp,
    changeSide,
    queryLeaderboard,
    queryGhosts,
    withdrawMint,
    withdrawGame,
    showNotification 
};