import React, { Component } from "react";
import logo from "../assets/images/resources/logo.png";
import {isConnected, walletAddress, connect} from '../assets/scripts/wallet.js';

export default class Nav extends Component {

  constructor() {
    super();
    this.state = {
      sticky: false,
      isWalletConnected: false
    };
  }

  isInHome = () => {
    return this.isInStake() === false
  }

  isInStake = () => {
    return window.location.pathname.startsWith("/stake");
  };

  handleButtonClick = (e) => {
    e.preventDefault();
    if(isConnected()) { 
      window.location.href = "/profile";
    } else {
      connect();
    }
  };

  componentDidMount() {

    this.setState({isWalletConnected: isConnected()});
    
    document.addEventListener('onAccountUpdate', () => {
      this.setState({isWalletConnected: isConnected()});
    });

    window.addEventListener("scroll", this.handleScroll);

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector(".menu-toggler");
    let mainNav = document.querySelector(".main-navigation");

    mainNavToggler.addEventListener("click", function () {
      mainNav.style.display =
        mainNav.style.display !== "block" ? "block" : "none";
    });
  };

  render() {
    return (
      <header className="site-header site-header__header-one">
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
            this.state.sticky ? "stricked-menu stricky-fixed" : ""
          }`}
          >
          <div className="container clearfix">
            <div className="logo-box clearfix">
              <a className="navbar-brand" href="/">
                <img
                  src={logo}
                  className="main-logo"
                  width="64"
                  alt="alter text"
                />
              </a>
            </div>
            <div className="main-navigation">
              <ul className="one-page-scroll-menu navigation-box">
                  <li>
                    <a href="/#mint">Mint</a>
                  </li>
                  <li>
                    <a href="/#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="/#team">Team</a>
                  </li>
                  <li>
                    <a href="/#faq">FAQ</a>
                  </li>
                  <li>
                    <a href="https://docs.avaxghost.com" target="_blank" rel="noreferrer">Docs</a>
                  </li>
              </ul>
            </div>
            <div className="right-side-box">
              <a className="thm-btn header__cta-btn" href={this.isInHome() ? "/stake" : "/"}>
                <span>{this.isInHome() ? "STAKE" : "MINT"}</span>
              </a>
              &nbsp;&nbsp;
              <a className="thm-btn header__cta-btn" href="https://game.avaxghost.com" target="__blank">
                <span>PLAY GAME</span>
              </a>
              &nbsp;&nbsp;
              <a className="thm-btn header__cta-btn" href="https://claim.avaxghost.com" target="__blank">
                <span>CLAIM</span>
              </a>
              &nbsp;&nbsp;
              <a className="thm-btn header__cta-btn" href="#" onClick={this.handleButtonClick}>
                <span>{this.state.isWalletConnected ? walletAddress() : "CONNECT"}</span>
              </a>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
