import { store } from 'react-notifications-component';

const AVALANCHE_MAINNET_PARAMS = {
    chainId: "0x"+(43114).toString(16),
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/']
};

// const AVALANCHE_TESTNET_PARAMS = {
//     chainId: "0x"+(43113).toString(16),
//     chainName: 'Avalanche FUJI C-Chain',
//     nativeCurrency: {
//         name: 'Avalanche',
//         symbol: 'AVAX',
//         decimals: 18
//     },
//     rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
//     blockExplorerUrls: ['https://testnet.snowtrace.io/']
// };

// Change here to select network!
const CURRENT_RPC = AVALANCHE_MAINNET_PARAMS;

async function copy(text, failure = () => {}) {
    const clipboard = navigator.clipboard;
    if(clipboard) {
        await clipboard.writeText(text);
        // alert('Copied');
        showNotification("Success", "Copied!");
    } else {
        // alert('Cannot copy ' + error);
        // showNotification("Cannot copy");
        failure();
    }
}

function isInt(n) {
    return n % 1 === 0;
}

function showNotification(title, message, type = "info", dismissAfter = 6000) {
    // log(title + "\n" + message);
    if(!store) {
        return;
    }
    store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: dismissAfter
        },
        slidingEnter: {
            duration: 400
        },
        slidingExit: {
            duration: 400
        }
    });
}

function randomNumber(min, max) {
    return min + Math.random() * (max - min);
}

function randomWalletAddress(mask = true) {
    var address = "0x";
    const chars = ['A', 'B', 'C', 'D', 'E', 'F'];
    for(var i = 0; i < 40; i++) {
        if(Math.random() < 0.5) {
            address += chars[(randomNumber(0, chars.length) | 0)];
        } else {
            address += (randomNumber(0, 10) | 0);
        }
    }
    return mask ? maskWalletAddress(address) : address;
}

function maskWalletAddress(address) {
    return address.substring(0, 6) + "..." + address.substring(address.length-4, address.length)
}

export { 
    CURRENT_RPC,
    isInt,
    showNotification,
    copy, 
    randomNumber,
    randomWalletAddress,
    maskWalletAddress
}
