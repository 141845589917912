import React, { Component } from "react";
import Helmet from "react-helmet";
// import ReactNotification from 'react-notifications-component';
import Delayed from "./Delayed.js";
import "react-modal-video/css/modal-video.min.css";
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/font-awesome.min.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { initialize as initializeAnalytics } from "../assets/scripts/analytics.js";
import { initialize as initializeWallet } from "../assets/scripts/wallet.js";
import { initialize as initializeContract } from "../assets/scripts/contract.js";

export default class Layout extends Component {

  componentDidMount() {

    const lazyFirebase = import('firebase/app');
    const lazyAnalytics = import('firebase/analytics');

    Promise.all([lazyFirebase, lazyAnalytics]).then(([firebase, analytics]) => {
      initializeAnalytics(firebase, analytics);
      initializeWallet();
      initializeContract();
    });

  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.pageTitle}</title>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap" />
        </Helmet>
        <Delayed waitBeforeShow={16}>
          {/* <ReactNotification /> */}
          <div className="page-wrapper">{this.props.children}</div>
        </Delayed>
      </div>
    )
  }

}
