// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZxtmM2a5vR9qekZ2g19wfOyR2D-tPc7M",
  authDomain: "avaxghost-676c2.firebaseapp.com",
  projectId: "avaxghost-676c2",
  storageBucket: "avaxghost-676c2.appspot.com",
  messagingSenderId: "945860340386",
  appId: "1:945860340386:web:49f6b0286553ba1eff45fb",
  measurementId: "G-0TSXMQ073K"
};

let firebaseInstance;

const initializeFirebase = async (firebase, analytics) => {

  if(typeof window !== 'undefined') {

    if (firebaseInstance) {
      return firebaseInstance;
    }
  
    firebaseInstance = firebase.initializeApp(firebaseConfig);
  
    return firebaseInstance;

  }
  
};

const initialize = async (firebase, analytics) => {
  await initializeFirebase(firebase, analytics);
};

export { initialize };